<template>
  <a-form-model
    class="search-form"
    ref="form"
    :model="form"
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
  >
    <a-row :gutter="60">
      <a-col :span="8">
        <a-form-model-item label="活动名称" prop="activityName">
          <a-input
            v-model="form.activityName"
            placeholder="请输入"
            allow-clear
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="8">
        <a-form-model-item label="活动时间" prop="activityTime">
          <a-range-picker
            v-model="form.activityTime"
            :placeholder="['开始时间', '结束时间']"
            format="YYYY-MM-DD HH:mm:ss"
            value-format="YYYY-MM-DD HH:mm:ss"
            :show-time="{ format: 'HH:mm:ss' }"
            style="width: 100%;"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="8">
        <a-form-model-item :wrapper-col="{span: 20, offset: 4}">
          <a-space>
            <a-button type="primary" @click="handleSearch">
              查询
            </a-button>
            <a-button @click="handleReset">
              重置
            </a-button>
          </a-space>
        </a-form-model-item>
      </a-col>
    </a-row>
  </a-form-model>
</template>

<script>
export default {
  name: 'SearchForm',
  props: {
    form: {
      type: Object,
      default () {
        return {}
      }
    },
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 }
    }
  },
  methods: {
    //
    handleSearch () {
      this.$emit('search', { ...this.form })
    },
    //
    handleReset () {
      this.$refs.form.resetFields()
      this.$emit('reset')
    }
  }
}
</script>

<style lang="scss" scoped>
.ant-form-item {
  margin-bottom: 10px;
}
</style>
