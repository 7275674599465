<template>
  <a-card class="page-container">
    <search-form
      :form="searchForm"
      @search="handleSearch"
      @reset="handleReset"
    />

    <data-table
      :loading="loading"
      :data="activityList"
      :pagination="pagination"
      @pagination-change="handlePaginationChange"
      @action-click="handleActionClick"
    />
  </a-card>
</template>

<script>
import SearchForm from './components/SearchForm'
import DataTable from './components/DataTable'
import activityApi from '@/api/activity'

export default {
  name: 'actAnalysis',
  components: {
    SearchForm,
    DataTable
  },
  data () {
    return {
      searchForm: {
        activityName: '',
        activityTime: []
      },
      loading: false,
      activityList: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0
      }
    }
  },
  created () {
    this.fetchActivityListLeader()
  },
  methods: {
    // 处理查询
    handleSearch () {
      this.pagination.current = 1
      this.fetchActivityListLeader()
    },
    // 处理重置
    handleReset () {
      this.pagination.current = 1
      this.fetchActivityListLeader()
    },
    // 处理分页改变
    handlePaginationChange (current, pageSize) {
      this.pagination.current = current
      this.pagination.pageSize = pageSize
      this.fetchActivityListLeader()
    },
    // 获取活动分析(运营组长/客户经理)活动列表
    async fetchActivityListLeader () {
      this.loading = true
      try {
        const { activityTime, ...restSearchForm } = this.searchForm
        const { current, pageSize } = this.pagination
        const params = {
          ...restSearchForm,
          publishTime: activityTime.length > 0 ? activityTime[0] : '',
          finishTime: activityTime.length > 0 ? activityTime[1] : '',
          page: current,
          size: pageSize
        }
        const { code, data, message } = await activityApi.fetchActivityListLeader(params)

        if (code === 200) {
          this.activityList = data.list
          this.pagination.total = data.total
          this.loading = false
        } else {
          this.$message.error(message)
          this.loading = false
        }
      } catch (e) {
        this.loading = false
      }
    },
    // 处理操作点击
    handleActionClick (type, record) {
      if (type === 'content') {
        this.$router.push({
          name: 'actManage:content',
          query: {
            activityId: record.id
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  padding: 0 !important;

  .data-table {
    margin: 10px 0 0;
  }
}
</style>
