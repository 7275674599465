<template>
  <div class="data-table">
    <a-table
      :loading="loading"
      :columns="columns"
      :data-source="data"
      :row-key="(record, index) => index"
      :pagination="false"
    >
      <div slot="activityTime" slot-scope="text, record">
        <div>开始时间：{{ record.publishTime || '--' }}</div>
        <div>结束时间：{{ record.finishTime || '--' }}</div>  
      </div>
      <div slot="action" slot-scope="text, record">
        <a @click="handleActionClick('content', record)">作品动态</a>
      </div>
    </a-table>
    <base-pagination
      :current-page="pagination.current"
      :page-size="pagination.pageSize"
      :total="pagination.total"
      @onChange="handlePaginationChange"
      @onShowSizeChange="handlePaginationChange"
    />
  </div>
</template>

<script>
const columns = [
  {
    title: '活动名称',
    dataIndex: 'activityName',
    key: 'activityName',
    width: 250
  },
  {
    title: '品牌',
    dataIndex: 'principalName',
    key: 'principalName',
    width: 250
  },
  {
    title: '活动时间',
    dataIndex: 'activityTime',
    key: 'activityTime',
    width: 250,
    scopedSlots: { customRender: 'activityTime' }
  },
  {
    title: '操作',
    key: 'action',
    width: 250,
    fixed: 'right',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'DataTable',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default () {
        return []
      }
    },
    pagination: {
      type: Object,
      default () {
        return {
          current: 1,
          pageSize: 10,
          total: 0
        }
      }
    }
  },
  data () {
    return {
      columns
    }
  },
  methods: {
    //
    handleActionClick(type, record) {
      this.$emit('action-click', type, { ...record })
    },
    //
    handlePaginationChange(current, pageSize) {
      this.$emit('pagination-change', current, pageSize)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
